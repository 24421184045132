export const vars = {
    BaseURL: process.env.REACT_APP_BACKEND_URL,
    apiKey: process.env.REACT_APP_API_KEY
    
}

export const apiRoutes = {
    cashea: {
      getCampaigns: vars.BaseURL + "/v1/cashea-campaign/",
      createCampaign: vars.BaseURL + "/v1/cashea-campaign/send-from-odoo-pricelist",
      sendProducts: vars.BaseURL + "/v1/cashea-prod/sendProducts",
      merchantsNames: vars.BaseURL + "/v1/merchants-name/merchantsName",
      syncMerchants: vars.BaseURL + "/v1/merchants-name/merchantsName/sync",
      getCategory: vars.BaseURL + "/v1/cashea-categories/",
      changeUrlCashea: vars.BaseURL + "/v1/cashea-acc/change-url-cashea",
      getUrlCashea: vars.BaseURL + "/v1/cashea-acc/get-url-cashea",
      getReport: vars.BaseURL + "/v1/report-cashea",

    },
    tracking: {
      getTracking: vars.BaseURL + "/v1/track/get-tracking"
    },
    vtex: {
      acc: vars.BaseURL + "/v1/vtex-acc",
      products: vars.BaseURL + "/v1/vtex-prod",
      getWarehouses: vars.BaseURL + "/v1/vtex-warehouse",
      syncProducts: vars.BaseURL + "/v1/vtex-order",
      categories: vars.BaseURL + "/v1/vtex-categories",
      campaigns: vars.BaseURL + "/v1/vtex-campaign",
    }
  };
  
  export const pageRoutes = {
    main: "/",
    auth: "/admin",
  };