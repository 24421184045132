import React, { useEffect } from 'react'
import PedidosYaStyle from '../../../../../assets/css/components/admin/plugins/PedidosYaStyle';
import { BiX } from 'react-icons/bi'
import { GlobalContext } from '../../../../../contexts/GlobalContext';
import { useState, useContext } from 'react';
import axios from 'axios';
import { keyAccessToken, keyUserId } from '../../../../../assets/js/SessionStorageNames';
import DateTime from '../../../reports/components/DateTime';
import {getMarketPlaces, sendCampaignVtex} from "../../../../../hooks/api/vtex";

export default function Campaign({userName}) {
    const [axiosCancelTokenSource] = useState(axios.CancelToken.source());
    const [showBadge, setShowBadge] = useState(false);
    const [colorError, setColorError] = useState(true);
    const [error, setError] = useState('');
    const [dateOne, setDataeOne] = useState('');
    const [dateTwo, setDataeTwo] = useState('');
    const globalContext = useContext(GlobalContext);
    const [isLoad, setIsLoad] = useState(false);
    const [priceLists, setPriceLists] = useState([]);
    const [percent, setPercent] = useState(0)
    const [pricesListId, setPricesListId] = useState(null);
    const [campaignName, setCampaignName] = useState('')
    const [marketPlaces, setMarketPlaces] = useState([])
    const [selectedMarketPlaces, setSelectedMarketPlaces] = useState([]);

    const handleSelectChange = (value) => {
   
        if (value && !selectedMarketPlaces.includes(value)) {
            setSelectedMarketPlaces([...selectedMarketPlaces, value]);
        }
    };

    const removeSelectedMarketplace = (id) => {
        setSelectedMarketPlaces(selectedMarketPlaces.filter(mpId => mpId !== id));
    };

    const closeBadge = (e) => {
        e.preventDefault();
        setShowBadge(false);
    };

    useEffect(() => {
        fetchMarketplaces()
    }, []);

    const fetchMarketplaces = async () => {
        try {
          const entId = globalContext.currentEnterpriseId;
          const resp = await getMarketPlaces(entId, userName)
          setMarketPlaces(resp.data.data.marketplaces);
        } catch (error) {
          console.log(error);
          await globalContext.showModalWithMsg('Ha ocurrido un error al cargar los marketplaces.');
        }
    }


    useEffect(() => {
        const entId = globalContext.currentEnterpriseId;
        if (!entId) return;
        setIsLoad(true);
        axios.get(
            `${process.env.REACT_APP_BACKEND_URL}/v1/odoo-pricelist/${entId}`, {
            cancelToken: axiosCancelTokenSource.token,
            headers: {
                "x-api-key": process.env.REACT_APP_API_KEY,
                "x-access-token": localStorage.getItem(keyAccessToken),
                "x-user-id": localStorage.getItem(keyUserId),
            },
        }
        ).then(resp => {
            const data = resp.data.data;
            setPriceLists(data.priceLists);
            setIsLoad(false);
        })
            .catch((error) => {
                setIsLoad(false);
                setColorError(true);
                setError(`Algo salio mal ${error}`);
            }
            );
    }, [globalContext.currentEnterpriseId]);


    const sendCampaign = async () => {

        const entId = globalContext.currentEnterpriseId;
        if (!entId) {
            setColorError(true);
            setError(`Debe seleccionar la empresa`);
            return;
        };
        setIsLoad(true)

        let fecha = new Date(dateTwo);
        // Sumar un día
        fecha.setDate(fecha.getDate() + 1);

        const data = {
            pricelistId: parseInt(pricesListId),
            dateFrom: `${dateOne}T04:00:00Z`,
            dateTo: `${fecha.toISOString().split('T')[0]}T03:59:59Z`,
            nameCampaign: campaignName,
            marketplaces: selectedMarketPlaces
        }

        try {
            const resp = await sendCampaignVtex(entId, userName, data)
            console.log(resp);
            setIsLoad(false);
            setShowBadge(true)
            setColorError(false);
            setError(`Proceso ejecutado con exito`);
        } catch (error) {
            console.log('error', error);
            setIsLoad(false);
            setColorError(true);
            setError(`Algo salio mal ${error}`);
            setShowBadge(true)
        }
    }

    return (
        <>
            <PedidosYaStyle.ContBadgeError active={showBadge} error={colorError}>
                <PedidosYaStyle.Text size=".875rem" cl="#FFFFFF">
                    {error}
                </PedidosYaStyle.Text>
                <PedidosYaStyle.BtnToggleBadge onClick={(e) => closeBadge(e)}>
                    <BiX />
                </PedidosYaStyle.BtnToggleBadge>
            </PedidosYaStyle.ContBadgeError>
            <PedidosYaStyle.ContForm>
                <PedidosYaStyle.HeaderForm>
                    <PedidosYaStyle.Text size="14pt" fw="500" cl="#898989">
                        Crear campaña para Vtex
                    </PedidosYaStyle.Text>
                </PedidosYaStyle.HeaderForm>
                <PedidosYaStyle.BodyForm>
                    <PedidosYaStyle.BodyFormGrid>

                        <PedidosYaStyle.ContInputAndLabel>
                            <PedidosYaStyle.Text size="12pt">
                                Fecha Desde
                            </PedidosYaStyle.Text>
                            <DateTime
                                setValue={setDataeOne}
                                valueDefaultText="Fecha Desde"
                            />
                        </PedidosYaStyle.ContInputAndLabel>

                        <PedidosYaStyle.ContInputAndLabel>
                            <PedidosYaStyle.Text size="12pt">
                                Fecha Hasta
                            </PedidosYaStyle.Text>
                            <DateTime
                                setValue={setDataeTwo}
                                valueDefaultText="Fecha de Hasta"
                            />
                        </PedidosYaStyle.ContInputAndLabel>

                        <PedidosYaStyle.ContInputAndLabel jsc="flex-start">
                            <PedidosYaStyle.Text size="12pt">
                                Lista de Precio
                            </PedidosYaStyle.Text>
                            <PedidosYaStyle.select onChange={(e) => setPricesListId(e.target.value)}>
                                <option key="" value="">
                                    Seleccione una Lista de Precio
                                </option>
                                {
                                    priceLists.map(pl => {
                                        return (
                                            <option key={pl.id} value={pl.id}>
                                                {pl.name}
                                            </option>
                                        );
                                    })
                                }
                            </PedidosYaStyle.select>
                        </PedidosYaStyle.ContInputAndLabel>

                        <PedidosYaStyle.ContInputAndLabel>
                            <PedidosYaStyle.Text size="12pt">
                                Porcentaje
                            </PedidosYaStyle.Text>
                            <PedidosYaStyle.Input style={{ marginBottom: 10 }} placeholder='Porcentaje' onChange={(e) => setPercent(e.target.value)} />
                        </PedidosYaStyle.ContInputAndLabel>

                        <PedidosYaStyle.ContInputAndLabel>
                            <PedidosYaStyle.Text size="12pt">
                                Nombre de la campaña
                            </PedidosYaStyle.Text>
                            <PedidosYaStyle.Input style={{ marginBottom: 10 }} placeholder='Nombre' onChange={(e) => setCampaignName(e.target.value)} />
                        </PedidosYaStyle.ContInputAndLabel>

                        <PedidosYaStyle.ContInputAndLabel jsc="flex-start">
                            <PedidosYaStyle.Text size="12pt">
                                MarketPlace de la campaña
                            </PedidosYaStyle.Text>
                            <PedidosYaStyle.select onChange={(e) => handleSelectChange(e.target.value)}>
                                <option key="" value="">
                                    Seleccione el marketplace
                                </option>
                                {
                                    marketPlaces?.map(mp => {
                                        return (
                                            <option key={mp.Id} value={mp.Id}>
                                                {mp.Name}
                                            </option>
                                        );
                                    })
                                }
                            </PedidosYaStyle.select>
                        </PedidosYaStyle.ContInputAndLabel>

                        

                    </PedidosYaStyle.BodyFormGrid>
                        <div style={{ display: 'grid', gridTemplateColumns: 'repeat(3, 1fr)', gap: '10px', minWidth: '200px', paddingTop: '3px' }}>                         
                               {selectedMarketPlaces.map(id => {
                              
                                const marketplace1 = marketPlaces.find(mp => mp.Id == id.toString());
                                return (
                                    <span key={id} style={{ marginRight: '10px', display: 'inline-block' }}>
                                        
                                        <PedidosYaStyle.MarketplaceButton onClick={() => removeSelectedMarketplace(id)} style={{ marginLeft: '5px', cursor: 'pointer' }}>
                                            {marketplace1?.Name}
                                        </PedidosYaStyle.MarketplaceButton>
                                    </span>
                                );
                            })}
                        </div>

                </PedidosYaStyle.BodyForm>
                <PedidosYaStyle.FooterForm>
                    <PedidosYaStyle.BtnSend disabled={isLoad} onClick={sendCampaign}>
                        {isLoad ? 'Enviando' : 'Enviar'}
                    </PedidosYaStyle.BtnSend>
                </PedidosYaStyle.FooterForm>
            </PedidosYaStyle.ContForm>
        </>
    )
}
