import React, { useContext, useEffect, useState } from "react";
import { Text } from "../../../../../../assets/css/components/admin/marketplace-to-marketplace/modals/ModalSelectStoreAndSection";
import {
  BtnClose,
  ContLabelStep,
  ContStep,
  BtnGeneral,
  ModalContainer,
} from "../../../../../../assets/css/components/admin/marketplace-to-marketplace/modal-post-product/ModalContStyles";
import { GlobalContext } from "../../../../../../contexts/GlobalContext";
import SelectCasheaCategory from "./SelectCasheaCategory";
import SetParamProdToPost from "./SetParamProdToPost";
import ViewProductsAfterToPost from "./ViewProductsAfterToPost";
import { BiX } from "react-icons/bi";
import ViewsBeforeToSend from "./ViewsBeforeToSend";
import axios from "axios";
import {
  keyAccessToken,
  keyUserId,
} from "../../../../../../assets/js/SessionStorageNames";
import postOneOdooMl from "../../functions/postOneOdooMl";
import getProductSpecificationAnicam from "../../functions/getProductSpecificationAnicam";
import postOneAnicamMl from "../../functions/postOneAnicamMl";
import { Col, Container, Row } from "react-bootstrap";
import { apiRoutes } from "../../../../../../config";
import postOneOdooCashea from "../../functions/postOneOdooCashea";

export const LayoutHeaderButtons = ({ back, close, next, text }) => {
  return (
    <Container>
      <Row>
        <Col xs={6}>
          <Text size='15pt' fw='500'>
            {text || ''}
          </Text>
        </Col>
        <Col xs={2}>{back}</Col>
        <Col xs={2}>{next}</Col>
        <Col xs={2}>{close}</Col>
      </Row>
    </Container>
  );
};

export default function LayoutModalCashea({
  currentStep,
  setCurrentStep,
  defaultPlugin,
  currentPlugin,
}) { 
  const globalContext = useContext(GlobalContext);
  const [msgSuccess, setMsgSuccess] = useState("");
  const [category, setcategory] = useState(null);
  const [isLoad, setIsLoad] = useState(true);
  const [paramsToSend, setParamsToSend] = useState([]);
  const [listinType, setListinType] = useState(null);
  const [publishGroup, setPublishGroup] = useState(null);
  const [officialStoreName, setOfficialStoreName] = useState([]);
  const [stores, setStores] = useState(null);
  const [errors, setErrors] = useState([]);
  const [data, setData] = useState([]);
  const [axiosCancelTokenSource] = useState(axios.CancelToken.source());
  const [descriptionTemplate, setDescriptionTemplate] = useState(null);
  const [descriptionTemplateAnicam, setDescriptionTemplateAnicam] =
    useState(null);
  const [listSkuAnicam, setlistSkuAnicam] = useState([]);
  const [descriptionProductsAnica, setDescriptionProductsAnica] = useState([]);
  const [sendAnicam, setSendAnicam] = useState(false);

  /**
   * Handle the axios error
   * @param {Error} err The thrown error
   * @param {string} msg A message to show in the modal
   * @param {boolean} consoleLog If should log the error in the console
   */
  const handleAxiosError = (err, msg = null, consoleLog = true) => {
    if (axios.isCancel(err)) return; //Don't show axios cancel error
    if (err.response && err.response.data) {
      if (err.response.data.message) {
        globalContext.showModalWithMsg(err.response.data.message);
      } else {
        globalContext.showModalWithMsg(err.response.data);
      }
    } else if (msg) {
      globalContext.showModalWithMsg(msg);
    }

    if (consoleLog) console.log("Error: ", err, "Response: ", err.response);
  };

  useEffect(() => {
    if (!globalContext.modalPostProduct) return;
    if (globalContext.pluginSearchForpost === "anicam") {
      //? buscaremos la descripcion para todos los productos
      setSendAnicam(true);
    } else {
      setSendAnicam(false);
    }
  }, [globalContext.pluginSearchForpost, globalContext.modalPostProduct]);

  useEffect(() => {
    if (!globalContext.modalPostProduct) return;
    if (globalContext.pluginSearchForpost === "anicam") {
      //? buscaremos la descripcion para todos los productos
      buildListSku(globalContext.listProductsToSend);
    }
  }, [globalContext.pluginSearchForpost, globalContext.modalPostProduct]);

  useEffect(() => {
    if (!globalContext.modalPostProduct) return;
    if (globalContext.pluginSearchForpost === "anicam") {
      //? buscaremos la descripcion para todos los productos
      if (listSkuAnicam.length === 0) return;
      getProductSpecificationAnicam(listSkuAnicam)
        .then((res) => {
          const data = res.data.data;
          setDescriptionProductsAnica([...data]);
          console.log("res de anicam", data);
        })
        .catch((err) => {
          console.log("errr des", err);
        });
    }
  }, [
    globalContext.pluginSearchForpost,
    globalContext.modalPostProduct,
    listSkuAnicam,
  ]);

  //? obtenemos la descripcion
  useEffect(() => {
    console.log('Obtenemos la desc')
    if (!globalContext.modalPostProduct) return;
    const entId = globalContext.currentEnterpriseId;
    if (!entId) return;
    if (currentPlugin !== "cashea") return;
    getDescriptionProduct(entId)
      .then((resp) => {
        if (
          resp.data.data.configData.descriptionTemplate ||
          resp.data.data.configData.descriptionTemplateAnicam
        ) {
          setDescriptionTemplate(resp.data.data.configData.descriptionTemplate);
          setDescriptionTemplateAnicam(
            resp.data.data.configData.descriptionTemplateAnicam
          );
        } else {
          handleAxiosError(
            "",
            "Para poder publicar es necesario una plantilla para la descripcion"
          );
        }
      })
      .catch((err) => {
        console.log("aqui", err);
        setDescriptionTemplate("");
        handleAxiosError(
          err,
          "Ocurrio un Error al cargar la plantilla de publicacion"
        );
      });
  }, [
    globalContext.currentEnterpriseId,
    globalContext.modalPostProduct,
    globalContext.pluginSearchForpost,
    currentPlugin,
    listSkuAnicam,
  ]);

  //? validamos y seteamos el arreglo de parametros a enviar
  useEffect(() => {
    if (!globalContext.modalPostProduct) return;
      if (currentPlugin !== "cashea") return;
      if (!descriptionTemplate) return;
      pushDataInParamsToSend();
  }, [
    globalContext.modalPostProduct,
    globalContext.pluginSearchForpost,
    currentPlugin,
    descriptionTemplate,
    descriptionProductsAnica,
  ]);

  const buildListSku = async (listProd) => {
    let listSku = [];
    for (const prod of listProd) {
      listSku.push(prod.sku);
    }
    setlistSkuAnicam(listSku);
  };

  const renderLabelStepML = () => {
    switch (currentStep) {
      case 2:
        return "Seleccione la categoria";
      case 3:
        return "Modifique los valores";
      case 4:
        return "Seleccione las tiendas oficiales";
      case 5:
        return "Proceso culminado";
      default:
        break;
    }
  };

  const renderStep = () => {
    switch (currentStep) {
      case 2:
        return (
          <SelectCasheaCategory
            isLoad={isLoad}
            setIsLoad={setIsLoad}
            setCategory={setcategory}
            currentStep={currentStep}
            setCurrentStep={setCurrentStep}
          />
        );
      case 3:
        return (
          <SetParamProdToPost
            isLoad={isLoad}
            setIsLoad={setIsLoad}
            paramsToSend={paramsToSend}
            setParamsToSend={setParamsToSend}
            listinType={listinType}
            publishGroup={publishGroup}
            setListinType={setListinType}
            setPublishGroup={setPublishGroup}
            validateInput={validateInput}
            category={category}
          />
        );
      case 4:
        return (
          <ViewsBeforeToSend
            isLoad={isLoad}
            setIsLoad={setIsLoad}
            paramsToSend={paramsToSend}
            setParamsToSend={setParamsToSend}
            listinType={listinType}
            publishGroup={publishGroup}
            officialStoreName={officialStoreName}
            setOfficialStoreName={setOfficialStoreName}
            stores={stores}
          />
        );
      case 5:
        return (
          <ViewProductsAfterToPost
            isLoad={isLoad}
            data={data}
          />
        );
      default:
        return (
          <SelectCasheaCategory
            category={category}
            setcategory={setcategory}
            isLoad={isLoad}
            setIsLoad={setIsLoad}
            setCurrentStep={setCurrentStep}
          />
        );
    }
  };

  const getDescriptionProduct = (entId) => {
    return axios.get(
      `${process.env.REACT_APP_BACKEND_URL}/v1/plugin-config/${entId}/odoo`,
      {
        cancelToken: axiosCancelTokenSource.token,
        headers: {
          "x-api-key": process.env.REACT_APP_API_KEY,
          "x-access-token": localStorage.getItem(keyAccessToken),
          "x-user-id": localStorage.getItem(keyUserId),
        },
      }
    );
  };

  const setDescriptionToPost = (desc) => {
    return desc.replace(/<[^>]*>?/g, " ");
  };


  function validateChangeStep() {
    const msg = [];
    paramsToSend.forEach((product) => {
      // chequear si title, price, brand y model tienen informacion
      if (!product.Titulo || !product.Precio || !product.sku || !product.body ) {
        msg.push(`Por favor verifique los datos del producto : ${product.title}, ya que presenta un valor no permitido en alguno de los sus campos (Titulo, Precio, Marca o Modelo)`);
      }
    });
    
    if (listinType === null || listinType === "") {
      msg.push("Debe seleccionar el tipo de publicacion.");
    }

    if(msg.length > 0) {
      globalContext.showModalWithMsg(
        msg.join(". "),
        "Por Favor complete los Campos Obligatorios"
      );
    } else {
      setCurrentStep(currentStep + 1);
    }
  }
  function validateInput(txt) {
    // se convierte el dato recibido en string
    txt = txt.toString();
    // Eliminar caracteres no deseados
    let resultado = txt.replace(/[^0-9.]/g, '');
    // Validar cantidad de caracteres después del punto
    let puntoIndex = resultado.indexOf('.');
    if (puntoIndex !== -1) {
      let caracteresDespuesDelPunto = resultado.substring(puntoIndex + 1);
      if (caracteresDespuesDelPunto.length > 2) {
        caracteresDespuesDelPunto = caracteresDespuesDelPunto.substring(0, 2);
      }
      resultado = resultado.substring(0, puntoIndex + 1) + caracteresDespuesDelPunto;
    }
    // Validar cantidad de puntos
    let puntosCount = resultado.split('.').length - 1;
    if (puntosCount > 1) {
      resultado = resultado.replace(/\./g, '');
    }
    return resultado;
  }
  //? push data in the paramsToSend
  const pushDataInParamsToSend = () => {
    //? validamos si el array del context no es vacio
    if (globalContext.listProductsToSend.length > 0) {
      let copyArr = paramsToSend;
      //? recorremos el array
      for (const v of globalContext.listProductsToSend) {
        //? despues seteamos los parametros 
        copyArr.push({
          _id: v._id,
          sku: v.sku,
          "Titulo": v.title.substring(0, 60),
          "Precio": v.price,
          body: setDescriptionToPost(v.description),
          "Marca": v.brand,
          "Aliado": v.merchant,
          "Imagen": v.image,
          "Categoría": category,
          "Categoría1": "",
          "Cuotas": listinType
        });
      }

      setParamsToSend(copyArr);
    }
  };



  const sendData = async () => {
    const enterpriseId = globalContext.currentEnterpriseId;
    if (!enterpriseId) return;
    setIsLoad(false);
    setCurrentStep(currentStep + 1);
    //axiosCancelTokenSource
    const Cuotas = listinType;
    const Categoria = category;
    
    try {
      await postOneOdooCashea(
        enterpriseId,
        paramsToSend,
        officialStoreName,
        Cuotas,
        Categoria
      );
      setIsLoad(true);
    } catch (error) {
      setIsLoad(true);
      console.error("error al publicar", error);
    }
  };

  const renderBtnStep = () => {
    switch (currentStep) {
      case 1:
        return (
          <div
            // style={{ width: 500, height: "auto" }}
            className='d-flex justify-content-between align-items-center'
          >
            <LayoutHeaderButtons
              text={renderLabelStepML()}
              close={
                <BtnGeneral
                  bgc='#7367F0'
                  color='#FFF'
                  onClick={() => globalContext.setModalPostProduct(false)}
                >
                  Cerrar
                </BtnGeneral>
              }
            />
          </div>
        );
      case 2:
        return (
          <div
            // style={{ width: 500, height: "auto" }}
            className='d-flex justify-content-between align-items-center'
          >
            <LayoutHeaderButtons
              text={renderLabelStepML()}
              back={
                <BtnGeneral
                  bgc='#F3F2F7'
                  color='#979797'
                  onClick={() => {
                    setCurrentStep(currentStep - 1);
                    defaultPlugin();
                  }}
                >
                  Volver
                </BtnGeneral>
              }
              close={
                <BtnGeneral
                  bgc='#7367F0'
                  color='#FFF'
                  onClick={() => globalContext.setModalPostProduct(false)}
                >
                  Cerrar
                </BtnGeneral>
              }
            />
          </div>
        );
      case 3:
        return (
          <div
            // style={{ width: 500, height: "auto" }}
            className='d-flex justify-content-between align-items-center'
          >
            <LayoutHeaderButtons
              text={renderLabelStepML()}
              back={
                <BtnGeneral
                  bgc='#F3F2F7'
                  color='#979797'
                  onClick={() => setCurrentStep(currentStep - 1)}
                >
                  Volver
                </BtnGeneral>
              }
              next={
                paramsToSend.length > 0 && (
                  <BtnGeneral
                    disabled={paramsToSend.length === 0}
                    onClick={validateChangeStep}
                  >
                    Siguiente
                  </BtnGeneral>
                )
              }
              close={
                <BtnGeneral
                  bgc='#7367F0'
                  color='#FFF'
                  onClick={() => globalContext.setModalPostProduct(false)}
                >
                  Cerrar
                </BtnGeneral>
              }
            />
          </div>
        );
      case 4:
        return (
          <div
            // style={{ width: 500, height: "auto" }}
            className='d-flex justify-content-between align-items-center'
          >
            <LayoutHeaderButtons
              text={renderLabelStepML()}
              back={
                <BtnGeneral
                  bgc='#F3F2F7'
                  color='#979797'
                  onClick={() => setCurrentStep(currentStep - 1)}
                >
                  Volver
                </BtnGeneral>
              }
              next={
                <BtnGeneral onClick={sendData}>
                  Publicar
                </BtnGeneral>
              }
              close={
                <BtnGeneral
                  bgc='#7367F0'
                  color='#FFF'
                  onClick={() => globalContext.setModalPostProduct(false)}
                >
                  Cerrar
                </BtnGeneral>
              }
            />
          </div>
        );
      case 5:
        return (
          <div
            // style={{ width: "100%", height: "auto" }}
            className='d-flex justify-content-center align-items-center'
          >
            <LayoutHeaderButtons
              text={renderLabelStepML()}
              back={
                <BtnGeneral
                  bgc='#F3F2F7'
                  color='#979797'
                  onClick={() => setCurrentStep(currentStep - 1)}
                >
                  Volver
                </BtnGeneral>
              }
              close={
                <BtnGeneral
                  bgc='#7367F0'
                  color='#FFF'
                  onClick={() => globalContext.setModalPostProduct(false)}
                >
                  Cerrar
                </BtnGeneral>
              }
            />
          </div>
        );
      default:
        break;
    }
  };

  const getEnterpriseConfig = () => {

    const entId = globalContext.currentEnterpriseId;
    if (!entId) return;
    const axiosCancelTokenSource = axios.CancelToken.source();
    axios
      .get(
        `${process.env.REACT_APP_BACKEND_URL}/v1/plugin-config/${entId}/ml`,
        {
          cancelToken: axiosCancelTokenSource.token,
          headers: {
            "x-api-key": process.env.REACT_APP_API_KEY,
            "x-access-token": localStorage.getItem(keyAccessToken),
            "x-user-id": localStorage.getItem(keyUserId),
          },
        }
      )
      .then((resp) => {
        const data = resp.data.data.configData;
        setPublishGroup(data.publishVariantsGroupOdooMl);
      })
      .catch((err) =>
        handleAxiosError(
          err,
          "Disculpe, no se pudo cargar la configuracion actual."
        )
      );
  };

  //? obtenemos las marcas
  useEffect(() => {
    const entId = globalContext.currentEnterpriseId;
    console.log('//////////////////////////////')
    if (!entId) return; 
    axios
      .get(`${apiRoutes.cashea.merchantsNames}/${entId}`, {
        cancelToken: axiosCancelTokenSource.token,
        headers: {
          "x-api-key": process.env.REACT_APP_API_KEY,
          "x-access-token": localStorage.getItem(keyAccessToken),
          "x-user-id": localStorage.getItem(keyUserId),
        },
        params:{
          enterpriseId: entId,
          name: 'all'
        }
      })
      .then((resp) => {
        const brands = Object.values(resp.data.data);
        setStores(brands || []);
      })
      .catch((err) => handleAxiosError(err));
  }, [globalContext.currentEnterpriseId]);

  //? al cerrar la modal
  useEffect(() => {
    if (!globalContext.modalPostProduct) {
      setCurrentStep(1);
      setMsgSuccess("");
      setIsLoad(true);
      setcategory(null);
    }
  }, [globalContext.modalPostProduct]);

  useEffect(() => {
    getEnterpriseConfig();
  }, []);

  return (
    <ModalContainer>
      <div
        style={{
          width: "100%",
          // display: "flex",
          justifyContent: "space-between",
        }} 
      >
        {renderBtnStep()}
      </div>

      <ContStep>{renderStep()}</ContStep>
    </ModalContainer>
  );
}
