import React, { useContext, useEffect, useState } from 'react'
import PedidosYaStyle from '../../../../../assets/css/components/admin/plugins/PedidosYaStyle'
import { GlobalContext } from '../../../../../contexts/GlobalContext';
import { accVtex} from "../../../../../hooks/api/vtex";

export default function Navbar(props) {
    const {setCurrentStep, currentStep, setUserName} = props;
    const globalContext = useContext(GlobalContext);
    const [listUsers, setListUsers] = useState([]);
    const changeStep = (v) => {
        setCurrentStep(v)
    }

    // Función para obtener los username desde la API
    const getAccVtex = async () => {
        try {
            const entId = globalContext.currentEnterpriseId;
            const res = await accVtex("GET",entId);
            console.log("res:", res);
            if (res && res.data) {
                setListUsers(Object.values(res.data));
            }
        } catch (error) {
            console.error("Error obteniendo username", error);
        }
    };

    useEffect(() => {
        if (globalContext.currentEnterpriseId) {
            getAccVtex();
        }
    }, [globalContext.currentEnterpriseId]);

      // useEffect para monitorear cambios en listUsers
    useEffect(() => {
        console.log(listUsers, "list username actualizada");
    }, [listUsers]); // Solo se ejecutará cuando `listUsers` cambie
    
    /*const handleSelectChange = (e) =>{
        const selectedValue = e.target.value;
        setUserName(selectedValue)
    }*/

    return (
        <PedidosYaStyle.ContNavbar>
            <PedidosYaStyle.MenuOptions>
                <PedidosYaStyle.OptionMenu active={currentStep === 4} onClick={() => changeStep(4)}>
                    Crear Campañas Vtex
                </PedidosYaStyle.OptionMenu>
                <PedidosYaStyle.OptionMenu active={currentStep === 5} onClick={() => changeStep(5)}>
                    Listado de Campañas Vtex
                </PedidosYaStyle.OptionMenu>
            </PedidosYaStyle.MenuOptions>
            {/*
            {listUsers.length ? (<PedidosYaStyle.SelectWeb style={{maxWidth:280, height:50}} onChange={handleSelectChange}>
                <option selected value='' >Seleccione una credencial</option>
                {listUsers.map((v, index) => (
                    <option value={v.username} key={index}>
                    {v.username}
                    </option>
                ))}
               
            </PedidosYaStyle.SelectWeb>): <p>No hay credencial registrada</p>}
            */}
            
        </PedidosYaStyle.ContNavbar>
    )
}
