import React, { useState } from 'react'
import PedidosYaStyle from '../../../../../assets/css/components/admin/plugins/PedidosYaStyle';
import Navbar from '../campaigns-components/Navbar';
import Campaign from '../campaigns-components/Campaign';
import CampaignList from '../campaigns-components/CampaignList';

export default function FormIndexVtexCampaign() {

    const [currentStep, setCurrentStep] = useState(5);//change to one
    const [userName, setUserName] = useState(null);//change to one

    const renderStep = () => {
        switch (currentStep) {
            case 4:
                return (<Campaign userName={userName} />)
            case 5:
                return (<CampaignList setCurrentStep={setCurrentStep} userName={userName} />)
            default:
                return (<CampaignList />);
        }
    }

    return (
        <PedidosYaStyle.Cont>
            <PedidosYaStyle.Header>
                <PedidosYaStyle.Text size="15pt" fw="500">
                    Configuracion para Vtex
                </PedidosYaStyle.Text>
            </PedidosYaStyle.Header>
            <Navbar currentStep={currentStep} setCurrentStep={setCurrentStep} setUserName={setUserName}  />
            <PedidosYaStyle.ContStep>
                {renderStep()}
            </PedidosYaStyle.ContStep>
        </PedidosYaStyle.Cont>
    )
}
