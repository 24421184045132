import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React, { useContext, useEffect, useState } from "react"
import LinksAnotherMenuCss from "../../../assets/css/components/admin/menus/LinksAnotherMenuCss";
import { GlobalContext } from "../../../contexts/GlobalContext";
import Routes from "../../../Routes";
import activeRouteV from "../../../assets/js/ActiveRoute";
import { Link } from "react-router-dom";
import {
    third,
    userRole
} from "../../../assets/js/SessionStorageNames";
export default function LinksAnotherMenu() {
    const r = Routes;
    const globalContext = useContext(GlobalContext);
    const [isThirdPartyUser, setIsThirdPartyUser] = useState(globalContext.third);
    const v_userRole = localStorage.getItem(userRole);

    /*
        Cualquier cambio que se realice en los path de las rutas (Routes.js) debe verse reflejado
        en los path que se encuentran mas abajo. 
    */
    //Coleccion de los path de cada ruta ordenado por categoria
    const catCampanas= ["/rates-woo","/campaings-df","/campaings-linio","/rates-ml","/rates-cashea","/rates-vtex"]
    const catScraping= ["/list-products-cbt","/masive-scraping","/scraping-by-url","/duplicate","/robot-facebook"]
    const catReportes= ["/cron-errors", "/amazon/grid"]
    const catAmazon= ["/translate-categories", "/link-categories", "/rangos-publicacion", "/load-url/xlsx"]
    const catProductos= ["/productos", "/orders", "/list-products-cbt-history-products", "/linkeo-masivo"]
    const catPublicacion= ["/send-products-m-to-m", "/filters", "/plantillas-publicacion"]
    const catConfiguracion= ["/user-config","/plugins/my-plugins","/remove-background-image","/enterprise","/mercantil-config","/link-city"]
    const catPruebas= ["/ml-questions"]

    //Padding de cada categoria (valor*10)px
    const paddingNivelCero = 0
    const paddingNivelUno = 3
    //const paddingNivelDos = 6
    //const paddingNivelTres = 9

    //Lista de cada una de las categorias (el primer registro es de control)
    const [listaCategorias, setListaCategorias] = useState({
        configuracion: [
            { id: '1', nombre: 'Configuración', padre: null, visible: false, padding: paddingNivelCero, ThirdPartyUser: true }
        ],
        campanas: [
            { id: '2', nombre: 'Campañas', padre: null, visible: false, padding: paddingNivelCero, ThirdPartyUser: false  }
        ],
        productos: [
            { id: '3', nombre: 'Productos', padre: null, visible: false, padding: paddingNivelCero, ThirdPartyUser: true  }
        ],
        publicacion: [
            { id: '4', nombre: 'Publicación', padre: '3', visible: false, padding: paddingNivelUno, ThirdPartyUser: true  }
        ],
        scraping: [
            { id: '5', nombre: 'Scraping', padre: null, visible: false, padding: paddingNivelCero, ThirdPartyUser: false  }
        ],
        amazon: [
            { id: '6', nombre: 'Amazon', padre: null, visible: false, padding: paddingNivelCero, ThirdPartyUser: true  }
        ],
        reportes: [
            { id: '7', nombre: 'Reportes', padre: null, visible: false, padding: paddingNivelCero, ThirdPartyUser: false  }
        ],
        pruebas: [
            { id: '8', nombre: 'Preguntas', padre: null, visible: false, padding: paddingNivelCero, ThirdPartyUser: false  }
        ],
    });
    
    const arreglarPadding = (padding) => {
        return `${padding * 10}px`;
    };

    //Se verifica si el titulo de la categoria se debe mostrar
    //una categoria hija solo se muestra si su padre se esta mostrando
    const mostrarHijo = (categoria) => {
        
        if (listaCategorias[categoria][0].padre) {  // Verifica si la categoría tiene un padre
            
            for (const propiedad of Object.keys(listaCategorias)) {
                if (listaCategorias[propiedad][0].id == listaCategorias[categoria][0].padre && listaCategorias[propiedad][0].visible) {
                    return true; // Retorna true si el elemento padre si es visible
                }
            }
            return false; // Retorna false si el elemento padre no es visible
        }
        else{
            return true; // Retorna true si no tiene elemento padre
        }
        
    };

    //Agrega cada ruta a la categoria correspondiente
    const seleccionarCategoria = (categoria) => {
        
        if(catScraping.includes(categoria.path)){
            listaCategorias.scraping.push(categoria)
        }
        else if(catCampanas.includes(categoria.path)){
            listaCategorias.campanas.push(categoria)
        }
        else if(catReportes.includes(categoria.path)){
            listaCategorias.reportes.push(categoria)
        }
        else if(catAmazon.includes(categoria.path)){
            listaCategorias.amazon.push(categoria)
        }
        else if(catProductos.includes(categoria.path)){
            listaCategorias.productos.push(categoria)
        }
        else if(catConfiguracion.includes(categoria.path)){
            listaCategorias.configuracion.push(categoria)
        }
        else if(catPruebas.includes(categoria.path)){
            listaCategorias.pruebas.push(categoria)
        }
        else if(catPublicacion.includes(categoria.path)){
            listaCategorias.publicacion.push(categoria)
        }
    }

    //De todas las rutas se filtran las necesarias para mostrar en el menu
    const filtrarCategoriasPrincipales = () => {
        r.map((l, i) => {
            if (l.isFather) {
                if (l.rolAccess.includes(v_userRole) || (isThirdPartyUser && ['Rangos Ml Amazon', "Plugins"].includes(l.name))) {
                    if(!l.enterpriseBlock.find((v) => v === globalContext.currentEnterpriseId)) {
                        if(isThirdPartyUser) {
                            if(l.third) {
                                seleccionarCategoria(l)
                            }
                        } else {
                            seleccionarCategoria(l)
                        }
                    } 
                }
            }
            
        })
    }

    //Establece la visibilidad de la categoria como la inversa actual
    const alternarVisibilidadCategoria = (categoria) => {
        cambiarVisibilidad(categoria, !listaCategorias[categoria][0].visible)
        //Si la categoria ahora no es visible se cancela la visibilidad de los hijos
        if(listaCategorias[categoria][0].visible){
            cancelarVisibilidadDeHijos(listaCategorias[categoria][0].id)
        }
    };

    //Actualiza la visibilidad 
    const cambiarVisibilidad = (categoria, visible) => {
        setListaCategorias(prevState => {
            const updatedArray = { ...prevState }; // Se crea una copia del estado actual
            updatedArray[categoria][0].visible = visible // Se cambia la visibilidad 
            return updatedArray; // Se devuelve el estado actualizado
        });
    };

    //Se cancela la visibilidad de las categorias que tengan como padre al parametro (padre)
    const cancelarVisibilidadDeHijos = (padre) => {  
        Object.keys(listaCategorias).forEach((categoria) => {
            if (listaCategorias[categoria][0].padre === padre) {
                cambiarVisibilidad(categoria, false)
                cancelarVisibilidadDeHijos(listaCategorias[categoria][0].id)
            }
        });
    };

    const toggleSidebar = (v) => {
        globalContext.setStatusMenu(v)
    }

    useEffect(() => {
        filtrarCategoriasPrincipales()
    },[])

    useEffect(() => {
        if(globalContext.third===null) {
            if(localStorage.getItem(third)){
                globalContext.setThird(JSON.parse(localStorage.getItem(third)));
            } else {
                globalContext.setThird(false);
            }
        }
        setIsThirdPartyUser(globalContext.third);
    },[globalContext.third])

    useEffect(() => {
        if (!v_userRole || v_userRole === undefined) {
            globalContext.setVerifyLogin(true);
        } else {
            globalContext.setVerifyLogin(false);
        }
    }, [v_userRole]);

    return (
        <LinksAnotherMenuCss.cont>
            <LinksAnotherMenuCss.Dashboard to={"/admin/dashboard"}>Dashboard</LinksAnotherMenuCss.Dashboard>
            {Object.keys(listaCategorias).map((categoria, i_1) => (
                <div key={i_1}>
                    {mostrarHijo(categoria) && ( isThirdPartyUser ? listaCategorias[categoria][0]?.ThirdPartyUser : true) && (    //Se comprueba la visibilidad en función del parentesco. (Si "productos" es visible entonces "publicacion" es seleccionable)
                        <React.Fragment >
                            <LinksAnotherMenuCss.Title padding={arreglarPadding(listaCategorias[categoria][0].padding)} onClick={() => { alternarVisibilidadCategoria(categoria) }}>
                                {listaCategorias[categoria][0].visible ? '-   ' : '+   '}{listaCategorias[categoria][0].nombre} 
                            </LinksAnotherMenuCss.Title>
    
                            {listaCategorias[categoria][0].visible &&   //Se verifica si la categoria es visible (Renderizas las opciones)
                                listaCategorias[categoria]
                                    .filter((_, index) => index !== 0)  // Se filtra el primer elemento que contiene información de configuración de cada categoría
                                    .map((l, i_2) => (
                                        <React.Fragment key={i_2}>
                                            {isThirdPartyUser ? (   //Opciones para ThirdPartyUser
                                                l.third ? (
                                                    <Link to={l.path === "/productos" ? `${l.layout + l.path}/list-products` : l.layout + l.path} style={{ color: `${activeRouteV(l.layout + l.path) ? '#FFFFFF' : '#707070'}` }}>
                                                        <LinksAnotherMenuCss.LinkSidebar padding={arreglarPadding(listaCategorias[categoria][0].padding + 2)} active={activeRouteV(l.layout + l.path)} onClick={() => { toggleSidebar(false) }}>
                                                            <span>
                                                                <FontAwesomeIcon icon={l.icon} />
                                                            </span>
                                                            <p>{'Rangos Ml Amazon' === l.name ? 'Rangos de Precios' : l.name}</p>
                                                        </LinksAnotherMenuCss.LinkSidebar>
                                                    </Link>
                                                ) : null
                                            ) : (
                                                <Link to={l.path === "/productos" ? `${l.layout + l.path}/list-products` : l.layout + l.path} style={{ color: `${activeRouteV(l.layout + l.path) ? '#FFFFFF' : '#707070'}` }}>
                                                    <LinksAnotherMenuCss.LinkSidebar padding={arreglarPadding(listaCategorias[categoria][0].padding + 2)} active={activeRouteV(l.layout + l.path)} onClick={() => { toggleSidebar(false) }}>
                                                        <span>
                                                            <FontAwesomeIcon icon={l.icon} />
                                                        </span>
                                                        <p>{l.name}</p>
                                                    </LinksAnotherMenuCss.LinkSidebar>
                                                </Link>
                                            )}
                                        </React.Fragment>
                                    ))
                            }
                        </React.Fragment>
                    )}
                    
                </div>
                
            ))}
            <LinksAnotherMenuCss.marginBottom></LinksAnotherMenuCss.marginBottom>

        </LinksAnotherMenuCss.cont>
    );
}
